<template>
  <div>
    <b-table
      :items="items"
      :fields="computedFields"
      responsive="sm"
      striped
      bordered
      hover
      outlined
      class="rounded-table"
    >
    </b-table>
  </div>
</template>

<script>
import { toCurrency } from "@/helpers/converters.js";

export default {
  name: "CommissionManagementTotalizer",
  props: {
    totalizer: Object,
  },
  data() {
    return {
      items: [],
    };
  },
  computed: {
    computedFields() {
      return [
        { key: "predicted_amount", label: "Valor previsto", class: "text-center" },
        { key: "predicted_qtd", label: "Qtde", class: "text-center" },
        { key: "received_amount", label: "Valor recebido", class: "text-center" },
        { key: "received_qtd", label: "Qtde", class: "text-center" }
      ];
    },
  },
  created() {
    this.fillData();
  },
  watch: {
    totalizer: {
      handler(newVal) {
        this.fillData();
      },
      deep: true,
    },
  },
  methods: {
    fillData() {
      const defaultTotalizer = {
        expected: {
          amount: 0,
          count: 0
        },
        billed: {
          amount: 0,
          count: 0
        }
      };

      const data = this.totalizer || defaultTotalizer;

      this.items = [
        {
          predicted_amount: toCurrency(data.expected.amount || 0),
          predicted_qtd: data.expected.count || 0,
          received_amount: toCurrency(data.billed.amount || 0),
          received_qtd: data.billed.count || 0
        },
      ];
    },
  },
};
</script>

<style scoped>
.text-center th {
  text-align: center;
}
.rounded-table {
  border-radius: 5px;
  /* overflow: hidden; */
  overflow-x: auto;
}
</style>
