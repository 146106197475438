<template>
  <div>
    <withdrawal-request-sidebar @formSaved="updateBalance" />
    <b-row class="match-height">
      <b-col md="2">
        <b-card class="earnings-card">
          <b-row>
            <b-col v-if="periodType == periodTypes.DATE" cols="auto">
              <div
                class="my-auto justify-content-center"
                v-if="!withdrawals || withdrawals.pending_request_amount == 0"
              >
                <div class="d-flex justify-content-between align-items-center">
                  <h4 class="m-0">Saldo</h4>
                </div>
                <div class="position-relative h-auto">
                  <div
                    :class="[
                      eyeOpenTotalizer ? '' : 'blur-effect',
                      'font-weight-bolder',
                      'mt-2',
                    ]"
                    @click="toSeeTotalizer"
                  >
                    <h3 class="font-weight-bolder mt-2">
                      {{ withdrawals ? withdrawals.balance : 0 | toCurrency }}
                    </h3>
                  </div>
                </div>
                <b-button
                  v-if="withdrawals && withdrawals.balance > 0"
                  class="mt-1"
                  variant="primary"
                  @click="openWithdrawalRequestSidebar"
                >
                  Solicitar saque
                </b-button>
                <b-button
                  v-else
                  class="mt-1"
                  variant="primary"
                  :to="{ name: 'withdrawal-list' }"
                >
                  Ver pagamentos
                </b-button>
              </div>
              <div class="my-auto" v-else>
                <h4>Saque solicitado</h4>
                <div class="position-relative h-auto">
                  <div
                    :class="[
                      eyeOpenTotalizer ? '' : 'blur-effect',
                      'font-weight-bolder',
                      'mt-2',
                    ]"
                    @click="toSeeTotalizer"
                  >
                    <h3 class="font-weight-bolder mt-2">
                      {{ withdrawals.pending_request_amount | toCurrency }}
                    </h3>
                  </div>
                </div>
                <b-button
                  class="mt-1"
                  variant="primary"
                  :to="{ name: 'withdrawal-list' }"
                >
                  Ver pagamentos
                </b-button>
              </div>
            </b-col>
            <b-col v-else cols="auto">
              <div>
                <h4>
                  <span v-if="periodType == periodTypes.PAST">Recebido</span>
                  <span v-if="periodType == periodTypes.FUTURE"
                    >Provisionado</span
                  >
                  em {{ totalItems || 0 }} meses
                </h4>
                <div class="position-relative h-auto">
                  <div
                    :class="[
                      eyeOpenTotalizer ? '' : 'blur-effect',
                      'font-weight-bolder',
                      'mt-2',
                    ]"
                    @click="eyeOpenTotalizer"
                  >
                    <h3
                      class="font-weight-bolder mt-2 text-primary text-center text-wrap"
                    >
                      Total
                      <br />
                      {{ totalizer.total_earned || 0 | toCurrency }}
                    </h3>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="10">
        <b-card no-body class="card-statistics">
          <b-card-header class="p-2 justify-content-start">
            <b-card-title class="mr-2">Totalizadores</b-card-title>
            <feather-icon
              v-if="eyeOpenTotalizer"
              icon="EyeIcon"
              class="mouse-pointer"
              size="18"
              stroke-width="3"
              @click="toSeeTotalizer()"
            />
            <feather-icon
              v-if="!eyeOpenTotalizer"
              icon="EyeOffIcon"
              class="mouse-pointer"
              size="18"
              stroke-width="3"
              @click="toSeeTotalizer()"
            />
          </b-card-header>
          <b-card-body>
            <b-row class="px-3">
              <div
                class="text-sm-left font-italic text-danger"
                v-if="periodType == periodTypes.DATE"
              >
                <span v-if="isEmployee">
                  Ao solicitar, o pagamento será feito em 3 dias úteis,
                  respeitando as regras:<br />
                  <ol>
                    <li>
                      Solicitações antes do dia 7, o recebimento será no dia 10
                      dia útil. (exemplo em dia útil: solicitou dia 6, receberá
                      no dia 10)
                    </li>
                    <li>
                      Solicitações após o dia 7, o recebimento será em 3 dias
                      úteis. (exemplo em dia útil: solicitou dia 9, receberá no
                      dia 12)
                    </li>
                  </ol>
                </span>
                <span v-else>
                  Ao solicitar, o pagamento será feito em 3 dias úteis,
                  respeitando as regras:<br />
                  <ol>
                    <li>
                      Solicitações antes do dia 12, o recebimento será no dia 15
                      dia útil. (exemplo em dia útil: solicitou dia 10, receberá
                      no dia 15)
                    </li>
                    <li>
                      Solicitações após o dia 12, o recebimento será em 3 dias
                      úteis. (exemplo em dia útil: solicitou dia 14, receberá no
                      dia 17)
                    </li>
                  </ol>
                </span>
              </div>
            </b-row>
            <b-row v-if="presentBillingDate">
              <b-col cols="12" md="12">
                <div class="d-flex align-items-center justify-content-end">
                  <b-button
                    variant="outline-primary"
                    @click.prevent="goToAdjustmentsPerMonth"
                  >
                    Ver lançamentos de ajuste do mês
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
          <div class="w-100 overflow-auto">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col" colspan="2" class="text-center">
                    <span
                      class="commission-totalizer-title"
                      :class="[eyeOpenTotalizer ? '' : 'blur-effect']"
                    >
                      EPs
                    </span>
                  </th>
                  <th scope="col" colspan="2" class="text-center">
                    <span
                      class="commission-totalizer-title"
                      :class="[eyeOpenTotalizer ? '' : 'blur-effect']"
                    >
                      Assinaturas
                    </span>
                  </th>
                  <th scope="col" colspan="2" class="text-center">
                    <span
                      class="commission-totalizer-title"
                      :class="[eyeOpenTotalizer ? '' : 'blur-effect']"
                    >
                      Soluções
                    </span>
                  </th>
                  <th
                    scope="col"
                    colspan="2"
                    class="text-center commission-totalizer-title"
                    :class="[eyeOpenTotalizer ? '' : 'blur-effect']"
                  >
                    Lançamentos
                  </th>
                  <th
                    scope="col"
                    colspan="2"
                    class="text-center commission-totalizer-title"
                    :class="[eyeOpenTotalizer ? '' : 'blur-effect']"
                  >
                    Total
                  </th>
                </tr>
                <tr>
                  <th scope="row"></th>
                  <th
                    class="text-center"
                    :class="[eyeOpenTotalizer ? '' : 'blur-effect']"
                  >
                    Valor
                  </th>
                  <th
                    class="text-center"
                    :class="[eyeOpenTotalizer ? '' : 'blur-effect']"
                  >
                    Qtde
                  </th>
                  <th
                    class="text-center"
                    :class="[eyeOpenTotalizer ? '' : 'blur-effect']"
                  >
                    Valor
                  </th>
                  <th
                    class="text-center"
                    :class="[eyeOpenTotalizer ? '' : 'blur-effect']"
                  >
                    Qtde
                  </th>
                  <th
                    class="text-center"
                    :class="[eyeOpenTotalizer ? '' : 'blur-effect']"
                  >
                    Valor
                  </th>
                  <th
                    class="text-center"
                    :class="[eyeOpenTotalizer ? '' : 'blur-effect']"
                  >
                    Qtde
                  </th>
                  <th
                    class="text-center"
                    :class="[eyeOpenTotalizer ? '' : 'blur-effect']"
                  >
                    Valor
                  </th>
                  <th
                    class="text-center"
                    :class="[eyeOpenTotalizer ? '' : 'blur-effect']"
                  >
                    Qtde
                  </th>
                  <th
                    class="text-center"
                    :class="[eyeOpenTotalizer ? '' : 'blur-effect']"
                  >
                    Valor
                  </th>
                  <th
                    class="text-center"
                    :class="[eyeOpenTotalizer ? '' : 'blur-effect']"
                  >
                    Qtde
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">
                    <span
                      class="commission-totalizer-title"
                      :class="[eyeOpenTotalizer ? '' : 'blur-effect']"
                      style="float: right"
                      >Realizadas</span
                    >
                  </th>
                  <td class="text-center">
                    <span
                      class="font-weight-bolder mb-0"
                      :class="[eyeOpenTotalizer ? '' : 'blur-effect']"
                      @click="toSeeTotalizer"
                    >
                      {{ totalizer.ep_amount || 0 | toCurrency }}
                    </span>
                  </td>
                  <td class="text-center">
                    <span
                      class="font-weight-bolder mb-0"
                      :class="[eyeOpenTotalizer ? '' : 'blur-effect']"
                      @click="toSeeTotalizer"
                    >
                      {{ totalizer.ep_count || 0 }}
                    </span>
                  </td>
                  <td class="text-center">
                    <span
                      class="font-weight-bolder mb-0"
                      :class="[eyeOpenTotalizer ? '' : 'blur-effect']"
                      @click="toSeeTotalizer"
                    >
                      {{ totalizer.signature_amount || 0 | toCurrency }}
                    </span>
                  </td>
                  <td class="text-center">
                    <span
                      class="font-weight-bolder mb-0"
                      :class="[eyeOpenTotalizer ? '' : 'blur-effect']"
                      @click="toSeeTotalizer"
                    >
                      {{ totalizer.signature_count || 0 }}
                    </span>
                  </td>
                  <td class="text-center">
                    <span
                      class="font-weight-bolder mb-0"
                      :class="[eyeOpenTotalizer ? '' : 'blur-effect']"
                      @click="toSeeTotalizer"
                    >
                      {{ totalizer.solution_amount || 0 | toCurrency }}
                    </span>
                  </td>
                  <td class="text-center">
                    <span
                      class="font-weight-bolder mb-0"
                      :class="[eyeOpenTotalizer ? '' : 'blur-effect']"
                      @click="toSeeTotalizer"
                    >
                      {{ totalizer.solution_count || 0 }}
                    </span>
                  </td>
                  <td class="text-center">
                    <span
                      class="font-weight-bolder mb-0"
                      :class="[eyeOpenTotalizer ? '' : 'blur-effect']"
                      @click="toSeeTotalizer"
                    >
                      {{
                        totalizer.adjustments_credit_amount -
                          totalizer.adjustments_debt_amount ||
                        totalizer.adjustments_amount ||
                        0 | toCurrency
                      }}
                    </span>
                  </td>
                  <td class="text-center">
                    <span
                      class="font-weight-bolder mb-0"
                      :class="[eyeOpenTotalizer ? '' : 'blur-effect']"
                      @click="toSeeTotalizer"
                    >
                      {{ totalizer.adjustments_count || 0 }}
                    </span>
                  </td>
                  <td class="text-center">
                    <span
                      class="font-weight-bolder mb-0"
                      :class="[eyeOpenTotalizer ? '' : 'blur-effect']"
                      @click="toSeeTotalizer"
                    >
                      {{ totalizer.total_earned || 0 | toCurrency }}
                    </span>
                  </td>
                  <td class="text-center">
                    <span
                      class="font-weight-bolder mb-0"
                      :class="[eyeOpenTotalizer ? '' : 'blur-effect']"
                      @click="toSeeTotalizer"
                    >
                      {{ totalizer.total_count || 0 }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <span
                      class="commission-totalizer-title"
                      :class="[eyeOpenTotalizer ? '' : 'blur-effect']"
                      style="float: right"
                      >Previstas</span
                    >
                  </th>
                  <td class="text-center">
                    <span
                      class="font-weight-bolder mb-0"
                      :class="[eyeOpenTotalizer ? '' : 'blur-effect']"
                      @click="toSeeTotalizer"
                    >
                      {{ totalizer.ep_expected_amount || 0 | toCurrency }}
                    </span>
                  </td>
                  <td class="text-center">
                    <span
                      class="font-weight-bolder mb-0"
                      :class="[eyeOpenTotalizer ? '' : 'blur-effect']"
                      @click="toSeeTotalizer"
                    >
                      {{ totalizer.ep_expected_count || 0 }}
                    </span>
                  </td>
                  <td class="text-center">
                    <span
                      class="font-weight-bolder mb-0"
                      :class="[eyeOpenTotalizer ? '' : 'blur-effect']"
                      @click="toSeeTotalizer"
                    >
                      {{
                        totalizer.subscription_expected_amount || 0 | toCurrency
                      }}
                    </span>
                  </td>
                  <td class="text-center">
                    <span
                      class="font-weight-bolder mb-0"
                      :class="[eyeOpenTotalizer ? '' : 'blur-effect']"
                      @click="toSeeTotalizer"
                    >
                      {{ totalizer.subscription_expected_count || 0 }}
                    </span>
                  </td>
                  <td class="text-center">
                    <span
                      class="font-weight-bolder mb-0"
                      :class="[eyeOpenTotalizer ? '' : 'blur-effect']"
                      @click="toSeeTotalizer"
                    >
                      {{
                        totalizer.financial_solutions_expected_amount ||
                        0 | toCurrency
                      }}
                    </span>
                  </td>
                  <td class="text-center">
                    <span
                      class="font-weight-bolder mb-0"
                      :class="[eyeOpenTotalizer ? '' : 'blur-effect']"
                      @click="toSeeTotalizer"
                    >
                      {{ totalizer.financial_solutions_expected_count || 0 }}
                    </span>
                  </td>
                  <td class="text-center">
                    <span
                      class="font-weight-bolder mb-0"
                      :class="[eyeOpenTotalizer ? '' : 'blur-effect']"
                      @click="toSeeTotalizer"
                    >
                      {{
                        totalizer.adjustments_expected_amount || 0 | toCurrency
                      }}
                    </span>
                  </td>
                  <td class="text-center">
                    <span
                      class="font-weight-bolder mb-0"
                      :class="[eyeOpenTotalizer ? '' : 'blur-effect']"
                      @click="toSeeTotalizer"
                    >
                      {{ totalizer.adjustments_expected_count || 0 }}
                    </span>
                  </td>
                  <td class="text-center">
                    <span
                      class="font-weight-bolder mb-0"
                      :class="[eyeOpenTotalizer ? '' : 'blur-effect']"
                      @click="toSeeTotalizer"
                    >
                      {{ totalizer.total_expected_amount || 0 | toCurrency }}
                    </span>
                  </td>
                  <td class="text-center">
                    <span
                      class="font-weight-bolder mb-0"
                      :class="[eyeOpenTotalizer ? '' : 'blur-effect']"
                      @click="toSeeTotalizer"
                    >
                      {{ totalizer.total_expected_count || 0 }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <b-card-body>
            <b-row
              class="justify-content-around justify-content-lg-between flex-wrap"
            >
              <b-col cols="12" class="mt-1">
                <b-media class="w-100" body>
                  <b-media-body class="my-auto w-100">
                    <div class="w-100 text-center">
                      <feather-icon
                        v-if="!visible"
                        icon="ChevronDownIcon"
                        class="text-danger"
                        size="45"
                        v-b-toggle.accordion-1
                        @click="visible = !visible"
                      />
                      <feather-icon
                        v-if="visible"
                        icon="ChevronUpIcon"
                        class="text-danger"
                        size="45"
                        v-b-toggle.accordion-1
                        @click="visible = !visible"
                      />
                    </div>
                    <b-collapse
                      id="accordion-1"
                      accordion="my-accordion"
                      role="tabpanel"
                      class="w-100"
                      :visible="visible"
                    >
                      <div
                        :class="[
                          eyeOpenTotalizer ? '' : 'blur-effect-chart',
                          'w-100 d-flex align-items-center justify-content-center',
                        ]"
                      >
                        <commissions-chart
                          :spinnerActive="loading"
                          :unBlur="toSeeTotalizer"
                        />
                      </div>
                    </b-collapse>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BPopover,
  BButton,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { mapActions, mapGetters } from "vuex";
import WithdrawalRequestSidebar from "@/modules/withdrawal/components/WithdrawalRequestSidebar";
import * as commissionPeriods from "@/constants/commission_periods";
import * as sharedTypes from "@/modules/shared/store/types";
import * as withdrawalTypes from "@/modules/withdrawal/store/types";
import * as accountTypes from "@/modules/account/store/types";
import CommissionsChart from "./CommissionsChart.vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    VueApexCharts,
    BPopover,
    BButton,
    WithdrawalRequestSidebar,
    CommissionsChart,
  },
  props: {
    totalizer: Object,
    periodType: String,
    totalItems: Number,
    loading: Boolean,
  },
  data() {
    return {
      eyeOpenTotalizer: false,
      visible: true,
    };
  },
  computed: {
    ...mapGetters({
      withdrawalSummary: sharedTypes.WITHDRAWAL_SUMMARY,
      presentBillingDate: sharedTypes.PRESENT_BILLING_DATE,
      paymentDeadline: sharedTypes.BILLING_PAYMENT_DEADLINE,
      user: accountTypes.USER,
    }),
    withdrawals: function () {
      return this.withdrawalSummary;
    },
    periodTypes: function () {
      return commissionPeriods;
    },
    total_count: function () {
      return (
        this.totalizer.signature_count +
        this.totalizer.ep_count +
        this.totalizer.financial_solutions_count +
        this.totalizer.adjustments_count
      );
    },
    average_amount: function () {
      return this.totalizer.total_earned / this.totalItems;
    },
    isEmployee: function () {
      return this.user?.is_employee || false;
    },
  },
  mounted() {
    this.getWithdrawalSummary().catch((error) => {
      this.toast({
        component: ToastificationContent,
        props: {
          title: "Oops!",
          text: "Ocorreu um erro ao recuperar o saldo para saque. Entre em contato com o setor de TI.",
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    });
    this.getPresentBillingDate().catch(() => {
      this.toast({
        component: ToastificationContent,
        props: {
          title: "Oops!",
          text: "Ocorreu um erro ao obter o dia do faturamento. Entre em contato com o setor de TI.",
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    });
    this.getPaymentDeadline().catch(() => {
      this.toast({
        component: ToastificationContent,
        props: {
          title: "Oops!",
          text: "Ocorreu um erro ao obter a quantidade de dias para pagamento. Entre em contato com o setor de TI.",
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    });
  },
  methods: {
    ...mapActions({
      getWithdrawalSummary: sharedTypes.GET_WITHDRAWAL_SUMMARY,
      openWithdrawalRequestSidebar:
        withdrawalTypes.OPEN_WITHDRAWAL_REQUEST_SIDEBAR,
      getPresentBillingDate: sharedTypes.GET_PRESENT_BILLING_DATE,
      getPaymentDeadline: sharedTypes.GET_BILLING_PAYMENT_DEADLINE,
    }),
    toSeeTotalizer() {
      if (event.target.closest(".mouse-pointer")) {
        this.eyeOpenTotalizer = !this.eyeOpenTotalizer;
      } else if (!this.eyeOpenTotalizer) {
        this.eyeOpenTotalizer = true;
      }
    },
    updateBalance() {
      return this.getWithdrawalSummary().catch((error) => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao recuperar o saldo para saque. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    },
    goToAdjustmentsPerMonth() {
      let month = this.presentBillingDate.month
      let year = this.presentBillingDate.year
      if(this.$route.params.year && this.$route.params.month) {
        month = this.$route.params.month
        year = this.$route.params.year
      }
      this.$router.push({
        name: "financial-adjustments-per-month",
        params: {
          month,
          year
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.commission-totalizer-title {
  font-weight: 500;
  font-size: 1rem;
}

.glass-effect {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.28);
  border-radius: 5px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.table th,
.table td {
  padding: 0.72rem;
}

.blur-effect {
  filter: blur(5px);
}

.mouse-pointer {
  cursor: pointer;
}
</style>
