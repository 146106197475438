<template>
  <div>
    <!-- Totalizadores -->
    <commissions-past-future-totalizer
      :periodType="periodType"
      :result="result"
      :loading="loading"
    />
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <!-- Filtros -->
      <commissions-list-filters
        :periodType="periodType"
        ref="filters"
        :key="`filter-commission-${isPeriodPast}`"
      />
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Search -->
          <b-col cols="12" md="12">
            <b-button
                variant="outline-primary"
                @click.prevent="redirectToCommissionExtract()"
                class="mr-1"
              >
                Extrato compilado
              </b-button>
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="outline-secondary"
                @click.prevent="clearSearch"
                class="mr-1"
                :disabled="loading"
              >
                Limpar filtros
              </b-button>
              <b-button
                variant="primary"
                @click.prevent="search(1)"
                :disabled="loading"
              >
                <b-spinner v-if="loading" small />
                <feather-icon v-else icon="SearchIcon" class="mr-50" />
                <span>
                  {{ loading ? "Aguarde..." : "Pesquisar" }}
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-row v-if="!loading" class="d-flex align-items-center justify-content-center m-1 mb-3">
        <commissions-past-future-by-financial-solution-chart
          :chartData="pastChartData"
          :loading="loading"
        />
      </b-row>
      <b-table
        hover
        class="text-center"
        :items="items"
        responsive
        :fields="tableColumns"
        show-empty
        empty-text="Não foram encontrados comissões para esta busca"
        :busy="loading"
        @row-clicked="openDetails"
      >
        <template #thead-top="data">
          <b-tr>
            <b-th colspan="1" class="header-spacing"></b-th>
            <b-th
              :colspan="isPeriodPast ? 2 : 1"
              class="header-spacing text-center border-left bg-info"
            >
              <span class="dark-font">ENTREVISTAS PAGAS</span>
            </b-th>
            <b-th
              :colspan="isPeriodPast ? 2 : 1"
              class="header-spacing text-center border-left border-right bg-blue bg-lighten-2"
            >
              <span class="text-white">ASSINATURAS</span>
            </b-th>
            <b-th
              :colspan="isPeriodPast ? 2 : 1"
              class="header-spacing text-center border-left border-right bg-danger bg-lighten-2 dark-font"
            >
              <span class="dark-font">SOLUÇÕES FINANCEIRAS</span>
            </b-th>
            <b-th colspan="1" class="header-spacing"></b-th>
          </b-tr>
          <b-tr>
            <b-th colspan="1" class="header-spacing"></b-th>
            <b-th
              class="header-spacing text-center border-left bg-info bg-lighten-3"
            >
              <span class="dark-font">
                {{ totalsByType.ep_expected_amount | toCurrency }}
              </span>
            </b-th>
            <b-th
              v-if="isPeriodPast"
              class="header-spacing text-center border-left bg-info"
            >
              <span class="dark-font">
                {{ totalsByType.ep_paid_amount | toCurrency }}
              </span>
            </b-th>
            <b-th
              class="header-spacing text-center border-left bg-blue bg-lighten-5"
            >
              <span class="text-white">
                {{ totalsByType.subscription_expected_amount | toCurrency }}
              </span>
            </b-th>
            <b-th
              v-if="isPeriodPast"
              class="header-spacing text-center border-left bg-blue bg-lighten-2"
            >
              <span class="text-white">
                {{ totalsByType.subscription_paid_amount | toCurrency }}
              </span>
            </b-th>
            <b-th
              class="header-spacing text-center border-left bg-danger bg-lighten-5"
            >
              <span class="dark-font">
                {{
                  totalsByType.financial_solutions_expected_amount | toCurrency
                }}
              </span>
            </b-th>
            <b-th
              v-if="isPeriodPast"
              class="header-spacing text-center border-left border-right bg-danger bg-lighten-2"
            >
              <span class="dark-font">
                {{ totalsByType.financial_solutions_paid_amount | toCurrency }}
              </span>
            </b-th>
            <b-th colspan="1" class="header-spacing"></b-th>
          </b-tr>
        </template>
        <!-- Column: Data -->
        <template #cell(date)="data">
          <div class="text-wrap text-center">
            <span class="align-text-top text-capitalize">{{
              data.item.date | monthYearAbbrev
            }}</span>
          </div>
        </template>
        <!-- Column: EP Prevista -->
        <template #cell(ep_expected_amount)="data">
          <div class="text-wrap text-center">
            <span class="align-text-top text-capitalize">{{
              data.item.ep_expected_amount || 0 | toCurrency
            }}</span>
          </div>
        </template>
        <!-- Column: EP Paga -->
        <template #cell(ep_paid_amount)="data">
          <div class="text-wrap text-center">
            <span class="align-text-top text-capitalize">{{
              data.item.ep_paid_amount || 0 | toCurrency
            }}</span>
          </div>
        </template>

        <!-- Column: Assinaturas Previstas-->
        <template #cell(subscription_expected_amount)="data">
          <div class="text-wrap text-center">
            <span class="align-text-top text-capitalize">{{
              data.item.subscription_expected_amount || 0 | toCurrency
            }}</span>
          </div>
        </template>
        <!-- Column: Assinaturas Paga-->
        <template #cell(subscription_paid_amount)="data">
          <div class="text-wrap text-center">
            <span class="align-text-top text-capitalize">{{
              data.item.subscription_paid_amount || 0 | toCurrency
            }}</span>
          </div>
        </template>

        <!-- Column: Soluções Financeiras Previstas-->
        <template #cell(financial_solutions_expected_amount)="data">
          <div class="text-wrap text-center">
            <span class="align-text-top text-capitalize">{{
              data.item.financial_solutions_expected_amount || 0 | toCurrency
            }}</span>
          </div>
        </template>
        <!-- Column: Soluções Financeiras Paga -->
        <template #cell(financial_solutions_paid_amount)="data">
          <div class="text-wrap text-center">
            <span class="align-text-top text-capitalize">{{
              data.item.financial_solutions_paid_amount || 0 | toCurrency
            }}</span>
          </div>
        </template>

        <template #cell(actions)="data">
          <div
            class="text-wrap text-center"
            v-if="data.item.has_details || !isPeriodPast"
          >
            <b-button
              :id="`commissions-detail-` + data.item.date"
              class="btn-icon mr-1"
              variant="flat"
              style="padding: 0"
              @click="openDetails(data.item)"
            >
              <feather-icon icon="EyeIcon" size="18" />
            </b-button>
            <b-tooltip
              :target="`commissions-detail-` + data.item.date"
              title="Ver extrato analítico"
              placement="top"
              boundary="document"
            ></b-tooltip>
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import _ from "lodash";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardHeader,
  BCardBody,
  BSpinner,
  BCardText,
  BMediaAside,
  BMediaBody,
  BCardTitle,
  BButtonGroup,
  BTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import CommissionsListFilters from "../components/CommissionsListFilters";
import CommissionsPastFutureTotalizer from "../components/CommissionsPastFutureTotalizer";
import { getRangeDates } from "@/helpers/date_picker";
import * as commissionPeriods from "@/constants/commission_periods";
import * as structureOptions from "@/constants/structure_options";
import * as types from "../store/types";
import CommissionPastTotalizer from "../components/CommissionPastTotalizer";
import CommissionsPastFutureByFinancialSolutionChart from "../components/CommissionsPastFutureByFinancialSolutionChart";
import { useWindowSize } from "@vueuse/core";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    BCardText,
    BCardTitle,
    BMediaAside,
    BMediaBody,
    BButtonGroup,
    BTooltip,
    vSelect,
    CommissionsListFilters,
    CommissionsPastFutureTotalizer,
    CommissionPastTotalizer,
    CommissionsPastFutureByFinancialSolutionChart,
    useWindowSize,
  },
  props: {
    periodType: String,
  },
  setup() {
    const { width, height } = useWindowSize();
    return { toast: useToast(), width, height };
  },
  data() {
    return {
      loading: false,
    };
  },
  watch: {
    periodType() {
      this.clearSearch();
    },
  },
  computed: {
    ...mapGetters({
      result: types.PAST_FUTURE_COMMISSIONS_SEARCH_RESULT,
    }),
    isPeriodPast: function () {
      return this.periodType == commissionPeriods.PAST;
    },
    items: function () {
      return this.result?.items || [];
    },
    totalsByType: function () {
      return this.result?.totalsByType || {};
    },
    pastChartData: function () {
      const categories = _.map(this.items, "date");
      const series = [
        {
          name: "EPs",
          data: _.map(
            this.items,
            this.periodType == commissionPeriods.PAST
              ? "ep_paid_amount"
              : "ep_expected_amount"
          ),
        },
        {
          name: "Assinaturas",
          data: _.map(
            this.items,
            this.periodType == commissionPeriods.PAST
              ? "subscription_paid_amount"
              : "subscription_expected_amount"
          ),
        },
        {
          name: "Soluções financeiras",
          data: _.map(
            this.items,
            this.periodType == commissionPeriods.PAST
              ? "financial_solutions_paid_amount"
              : "financial_solutions_expected_amount"
          ),
        },
        {
          name: "Total",
          data: _.map(this.items, (item) => {
            if (this.periodType == commissionPeriods.PAST) {
              return (
                (item.ep_paid_amount || 0) +
                (item.subscription_paid_amount || 0) +
                (item.financial_solutions_paid_amount || 0)
              );
            }
            return (
              (item.ep_expected_amount || 0) +
              (item.subscription_expected_amount || 0) +
              (item.financial_solutions_expected_amount || 0)
            );
          }),
        },
      ];
      return {
        categories,
        series,
      };
    },
    tableColumns: function () {
      if (this.isPeriodPast) {
        return [
          { key: "date", label: "Período", sortable: true },
          {
            key: "ep_expected_amount",
            label: "Valor previsto",
            sortable: true,
            thClass: "text-center",
          },
          {
            key: "ep_paid_amount",
            label: "Valor recebido",
            sortable: true,
            thClass: "text-center",
          },
          {
            key: "subscription_expected_amount",
            label: "Valor previsto",
            sortable: true,
            thClass: "text-center",
          },
          {
            key: "subscription_paid_amount",
            label: "Valor recebido",
            sortable: true,
            thClass: "text-center",
          },
          {
            key: "financial_solutions_expected_amount",
            label: "Valor previsto",
            sortable: true,
            thClass: "text-center",
          },
          {
            key: "financial_solutions_paid_amount",
            label: "Valor recebido",
            sortable: true,
            thClass: "text-center",
          },
          { key: "actions", label: "Ações", thClass: "text-center" },
        ];
      } else {
        return [
          { key: "date", label: "Período", sortable: true },
          {
            key: "ep_expected_amount",
            label: "Valor previsto",
            sortable: true,
            thClass: "text-center",
          },
          {
            key: "subscription_expected_amount",
            label: "Valor previsto",
            sortable: true,
            thClass: "text-center",
          },
          {
            key: "financial_solutions_expected_amount",
            label: "Valor previsto",
            sortable: true,
            thClass: "text-center",
          },
          { key: "actions", label: "Ações", thClass: "text-center" },
        ];
      }
    },
    colsPartition: function () {
      let cols;
      if (this.width >= 1800) {
        cols = {
          table: 8,
          chart: 4,
        };
      } else if (this.width <= 1080) {
        cols = {
          table: 12,
          chart: 12,
        };
      } else {
        cols = {
          table: 6,
          chart: 6,
        };
      }
      return cols;
    },
  },
  mounted() {
    this.search();
  },
  methods: {
    ...mapActions({
      searchComissions: types.SEARCH_PAST_FUTURE_COMMISSIONS,
    }),
    search() {
      if (!this.$refs.filters) return;
      this.loading = true;
      const {
        customer,
        partnerCompany,
        product,
        consultant,
        structureOption,
        franchise,
        creditType,
        rangeDate,
        maxMonths,
        role,
        specialist_consultant_id,
        onlyNotConciliated,
        myCommissions,
        financialSolution,
      } = this.$refs.filters;
      const { year, month, periodType } = this;
      let launchDate = getRangeDates(rangeDate);
      const request = {
        customer,
        partner_company: partnerCompany,
        product,
        consultant,
        structure_option: structureOption,
        franchise,
        credit_type: creditType,
        max_months: maxMonths,
        year,
        month,
        periodType,
        launch_date_start: launchDate.start,
        launch_date_end: launchDate.end,
        role,
        specialist_consultant_id,
        onlyNotConciliated,
        my_commissions: myCommissions,
        financialSolution,
      };
      this.searchComissions(request)
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao buscar as comissões. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openDetails(item) {
      if (item.has_details || !this.isPeriodPast) {
        var date = item.date.split("-");
        var routeData = this.$router.resolve({
          name: "commissions",
          params: { year: date[0], month: date[1] },
          query: { consultant: this.$refs.filters.consultant },
        });
        setTimeout(() => {
          window.open(routeData.href, "_blank");
        });
      }
    },
    redirectToCommissionExtract() {
      this.$router.push({
        name: 'commissions-receipt',
        params: {
          type: this.periodType,
        },
      });
    },
    clearSearch() {
      this.$refs.filters.customer = undefined;
      this.$refs.filters.partnerCompany = undefined;
      this.$refs.filters.product = undefined;
      this.$refs.filters.consultant = undefined;
      this.$refs.filters.structureOption = structureOptions.OWN;
      this.$refs.filters.franchise = undefined;
      this.$refs.filters.creditType = undefined;
      this.$refs.filters.rangeDate = undefined;
      this.$refs.filters.maxMonths = undefined;
      this.$refs.filters.role = undefined;
      this.$refs.filters.specialist_consultant_id = undefined;
      this.$refs.filters.onlyNotConciliated = undefined;
      this.search();
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.img_action {
  width: 16px;
  height: 16px;
}
#buttons {
  border: 0px;
}
.dark-font {
  color: #6e6b7b;
}
</style>
