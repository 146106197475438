<template>
  <div id="user-profile" class="profile">
    <!-- profile info  -->
    <section id="profile-info">
      <b-row>
        <!-- content -->
        <b-col cols="12">
          <!-- navigation -->
          <div class="nav-bar">
            <div class="d-flex align-items-center">
              <h2> Minhas comissões </h2>
              <b-nav pills class="ml-4">
                <b-nav-item v-if="periodType == periods.DATE && currentMonth === -1" id="comments"
                  :to="{ name: 'commissions' }" :active="currentMonth == -1" class="profile-nav mr-1">
                  {{ month | monthYear(year) }}
                </b-nav-item>
                <b-nav-item id="comments" :to="{ name: 'commissions-past' }" :active="periodType == periods.PAST"
                  class="profile-nav mr-1">
                  PASSADAS
                </b-nav-item>
                <b-nav-item id="contracts" :to="{ name: 'commissions' }"
                  :active="periodType == periods.DATE && currentMonth === 1" class="profile-nav mr-1">
                  <span v-if="presentBillingDate">
                    {{ presentBillingDate.month | monthYear(presentBillingDate.year) }}
                  </span>
                  <b-spinner v-else variant="secondary" small />
                </b-nav-item>
                  <b-nav-item 
                    :to="{name:'commissions-future'}"
                    :active="periodType == periods.FUTURE"  
                    class="profile-nav mr-1"
                  >
                    FUTURAS
                  </b-nav-item>
                  <b-nav-item 
                    v-if="periodType == periods.DATE && currentMonth === 2"
                    id="comments"
                    :to="{name:'commissions-past'}"
                    :active="currentMonth === 2" 
                    class="profile-nav mr-1"
                  >
                    {{ month | monthYear(year) }}
                  </b-nav-item> 
              </b-nav>
            </div>
          </div>
          <!-- navigation -->
          <commissions-current-list v-if="periodType == periods.DATE" ref="current" :year="year" :month="month" />
          <commissions-past-future-list v-if="periodType != periods.DATE" :periodType="periodType" ref="pastFuture" />
        </b-col>
        <!-- content -->
      </b-row>
    </section>
    <!--/ profile info  -->
  </div>
</template>

<script>
import { BRow, BCol, BNav, BNavItem, BSpinner } from 'bootstrap-vue'
import CommissionsCurrentList from './CommissionsCurrentList'
import CommissionsPastFutureList from './CommissionsPastFutureList'
import { mapActions, mapGetters } from 'vuex'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import * as commissionPeriod from '@/constants/commission_periods'
import * as sharedTypes from '@/modules/shared/store/types'


/* eslint-disable global-require */
export default {
  components: {
    BRow,
    BCol,
    BNav,
    BNavItem,
    BSpinner,
    CommissionsCurrentList,
    CommissionsPastFutureList
  },
  props: {
    periodType: {
      type: String,
      default: commissionPeriod.DATE,
    },
  },
  setup() {
    return { toast: useToast() }
  },
  data() {
    return {
      navigation: undefined,
      presentBillingMonthAndYear: undefined
    }
  },
  computed: {
    ...mapGetters({
      presentBillingDate: sharedTypes.PRESENT_BILLING_DATE
    }),
    periods: function () {
      return commissionPeriod
    },
    year: function () {
      if (this.$route.params.year) {
        return this.$route.params.year
      }
      return this.presentBillingDate?.year
    },
    month: function () {
      if (this.$route.params.month) {
        return this.$route.params.month
      }
      return this.presentBillingDate?.month 
    },
    currentMonth: function () {
      let currentYear = new Date().getFullYear()
      let currentMonth = new Date().getMonth() + 1
      if (!this.$route.params.month
        && !this.$route.params.year
      ) {
        return 1
      } else if (
        this.year > currentYear ||
        (this.year == currentYear && this.month > currentMonth)
      ) {
        return 2
      }
      return -1
    }
  },
  mounted() {
    this.getPresentBillingDate()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: 'Oops!',
            text: 'Ocorreu um erro ao obter o dia do faturamento. Entre em contato com o setor de TI.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  methods: {
    ...mapActions({
      getPresentBillingDate: sharedTypes.GET_PRESENT_BILLING_DATE
    }),
  }
}
/* eslint-disable global-require */
</script>

<style lang="scss" >
@import '@core/scss/vue/pages/page-profile.scss';


.dark-layout .profile-nav {
  background-color: $theme-dark-card-bg !important;
}

.profile-nav {
  width: 15rem;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  background-color: #ffff;
}
</style>
