<template>
  <div class="w-100" @click="unBlur()">
    <div v-if="spinnerActive" class="w-100 d-flex justify-content-center">
      <b-spinner class="mt-2" variant="secondary" large />
    </div>
    <b-row v-else-if="hasValidSeries && barChartSeries && pieChartSeries">
      <b-col cols="6">
        <vue-apex-charts
          type="bar"
          width="100%"
          height="300"
          :options="barChartOptions"
          :series="barChartSeries"
        ></vue-apex-charts>
      </b-col>
      <b-col cols="6">
        <vue-apex-charts
          type="pie"
          width="100%"
          height="300"
          :options="pieChartOptions"
          :series="pieChartSeries"
        ></vue-apex-charts>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { mapGetters } from "vuex";
import * as types from "../store/types";
import { useToast } from "vue-toastification/composition";
import useAppConfig from "@core/app-config/useAppConfig";
import { toCurrency } from "@/helpers/converters.js";
import _ from "lodash";

export default {
  components: { VueApexCharts },
  props: { spinnerActive: Boolean, unBlur: Function },
  setup() {
    const { skin } = useAppConfig();
    return { toast: useToast(), skin };
  },
  data() {
    return {
      primaryColor: "#48da89",
      secondaryColor: "#f26237",
      defaultColor: "#b4b7bd",
    };
  },
  computed: {
    ...mapGetters({ chartItems: types.CURRENT_COMMISSIONS_SEARCH_RESULT }),
    hasValidSeries() {
      return this.chartItems?.totalizers;
    },
    barChartSeries() {
      const {
        ep_amount,
        signature_amount,
        solution_amount,
        adjustments_amount,
      } = this.chartItems?.totalizers || {};
      return [
        {
          name: "Valores",
          data: [
            ep_amount || 0,
            signature_amount || 0,
            solution_amount || 0,
            adjustments_amount || 0,
          ],
        },
      ];
    },
    barChartOptions() {
      return {
        stroke: {
          width: [0, 4],
        },
        title: {
          text: "Valores",
          style: {
            color: this.skin === "dark" ? this.defaultColor : "",
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            distributed: true, // This enables individual color settings for each bar
          },
        },
        colors: ["#00cfe8", "#3a4dc1", "#f08182", "#f26237"],
        dataLabels: {
          enabled: false,
        },
        labels: [
          "Entrevistas pagas",
          "Assinaturas",
          "Soluções",
          "Lançamentos"
        ],
        legend: {
          show: false,
        },
        markers: {
          size: [4, 7],
        },
        chart: {
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
        },
        xaxis: {
          type: "category",
          labels: {
            style: {
              colors: this.skin === "dark" ? this.defaultColor : "",
            },
          },
        },
        yaxis: [
          {
            axisBorder: {
              show: true,
              color: this.primaryColor,
            },
            title: {
              text: "Valores",
              style: {
                color: this.defaultColor,
              },
            },
            labels: {
              formatter: (value) => toCurrency(value),
              style: {
                colors: this.defaultColor,
              },
            },
          },
        ],
      };
    },
    pieChartSeries() {
      const {
        ep_count,
        signature_count,
        solution_count,
        adjustments_count
      } = this.chartItems?.totalizers || {};
      return [
        ep_count || 0,
        signature_count || 0,
        solution_count || 0,
        adjustments_count || 0
      ];
    },
    pieChartOptions() {
      const {
        ep_count,
        signature_count,
        solution_count,
        adjustments_count
      } = this.chartItems?.totalizers || {};
      return {
        chart: { type: "pie" },
        labels: [
          `Entrevistas pagas: ${ep_count || 0}`,
          `Assinaturas: ${signature_count || 0}`,
          `Soluções: ${solution_count || 0}`,
          `Lançamentos: ${adjustments_count || 0}`
        ],
        colors: ["#00cfe8", "#3a4dc1", "#f08182", "#f26237"],
        title: {
          text: "Quantidades",
          style: {
            color: this.skin === "dark" ? this.defaultColor : "",
          },
        },
        theme: {
          palette: "palette10",
        },
        legend: {
          labels: {
            colors: this.skin === "dark" ? this.defaultColor : "",
            useSeriesColors: false,
          },
          position: "right",
          horizontalAlign: "left",
          offsetX: 10,
          offsetY: 20,
          itemMargin: {
            horizontal: 0,
            vertical: 6,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: { chart: { width: 200 }, legend: { position: "bottom" } },
          },
        ],
      };
    },
  },
};
</script>
