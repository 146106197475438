<template>
  <div class="w-100" @click="unBlur()">
    <b-spinner v-if="spinnerActive" class="mt-2" variant="secondary" large />
    <vue-apex-charts
      v-else-if="series"
      type="line"
      width="100%"
      height="300"
      :options="chartOptions"
      :series="series"
    ></vue-apex-charts>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { mapGetters } from "vuex";
import * as types from "../store/types";
import { useToast } from "vue-toastification/composition";
import useAppConfig from "@core/app-config/useAppConfig";
import { toCurrency } from "@/helpers/converters.js";
import _ from "lodash";
import * as commissionPeriods from "@/constants/commission_periods";

export default {
  components: {
    VueApexCharts,
  },
  props: {
    spinnerActive: Boolean,
    unBlur: Function,
    periodType: String,
  },
  setup() {
    const { skin } = useAppConfig();
    return { toast: useToast(), skin };
  },
  data() {
    return {
      commissionPeriods,
      loading: {
        search: false,
      },
      primaryColor: "#48da89",
      secondaryColor: "#f26237",
      defaultColor: "#b4b7bd",
    };
  },
  computed: {
    ...mapGetters({
      result: types.PAST_FUTURE_COMMISSIONS_SEARCH_RESULT,
    }),

    values() {
      const values = this.result?.items || [];

      values.sort((a, b) => {
        const [aYear, aMonth] = a.date.split("-");
        const [bYear, bMonth] = b.date.split("-");
        return new Date(aYear, aMonth - 1) - new Date(bYear, bMonth - 1);
      });

      return values;
    },

    series() {
      if (this.periodType == commissionPeriods.PAST) {
        var totalValueData = this.values?.map(
          (item) =>
            (item.subscription_paid_amount || 0) +
            (item.ep_paid_amount || 0) +
            (item.financial_solutions_paid_amount || 0)
        );
        var totalCountData = this.values?.map(
          (item) =>
            (item.subscription_paid_count || 0) +
            (item.ep_paid_count || 0) +
            (item.financial_solutions_paid_count || 0)
        );
      } else {
        var totalValueData = this.values?.map(
          (item) =>
            (item.subscription_expected_amount || 0) +
            (item.ep_expected_amount || 0) +
            (item.financial_solutions_expected_amount || 0)
        );
        var totalCountData = this.values?.map(
          (item) =>
            (item.subscription_expected_count || 0) +
            (item.ep_expected_count || 0) +
            (item.financial_solutions_expected_count || 0)
        );
      }
      return [
        {
          name: "Valores",
          type: "column",
          data: totalValueData || [],
        },
        {
          name: "Quantidade",
          type: "line",
          data: totalCountData || [],
        },
      ];
    },

    chartOptions() {
      const labels = this.values?.map((item) => {
        const [year, month] = item.date.split("-");
        return new Date(year, month - 1, 1).toLocaleString("default", {
          month: "long",
          year: "numeric",
        });
      });
      return {
        stroke: {
          width: [0, 4],
        },
        title: {
          style: {
            color: this.skin === "dark" ? this.defaultColor : "",
          },
        },
        colors: [this.primaryColor, this.secondaryColor],
        dataLabels: {
          enabled: false,
          enabledOnSeries: [1],
        },
        labels: labels,
        legend: {
          labels: {
            colors: this.skin === "dark" ? this.defaultColor : "",
            useSeriesColors: false,
          },
        },
        markers: {
          size: [4, 7],
        },
        chart: {
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
        },
        xaxis: {
          type: "category",
          labels: {
            style: {
              colors: this.skin === "dark" ? this.defaultColor : "",
            },
          },
        },
        yaxis: [
          {
            axisBorder: {
              show: true,
              color: this.primaryColor,
            },
            title: {
              text: "Valores",
              style: {
                color: this.defaultColor,
              },
            },
            labels: {
              formatter: (value) => toCurrency(value),
              style: {
                colors: this.defaultColor,
              },
            },
          },
          {
            opposite: true,
            axisBorder: {
              show: true,
              color: this.secondaryColor,
            },
            title: {
              text: "Quantidade",
              style: {
                color: this.defaultColor,
              },
            },
            labels: {
              style: {
                colors: this.defaultColor,
              },
            },
            decimalsInFloat: 0,
          },
        ],
      };
    },
  },
};
</script>
