<template>
  <div>
    <withdrawal-request-sidebar @formSaved="updateBalance" />
    <b-row class="match-height">
      <b-col md="2">
        <b-card class="earnings-card">
          <b-row>
            <b-col cols="auto">
              <div>
                <h4>
                  <span>{{
                    periodType === periodTypes.PAST
                      ? "Recebido"
                      : "Provisionado"
                  }}</span>
                  em {{ itemsQuantity || 0 }} meses
                </h4>
                <div class="position-relative h-auto">
                  <div
                    :class="[
                      eyeOpenTotalizer ? '' : 'blur-effect',
                      'font-weight-bolder',
                      'mt-2',
                    ]"
                    @click="eyeOpenTotalizer"
                  >
                    <h4
                      @click="toSeeTotalizer()"
                      class="font-weight-bolder mt-2 text-primary text-center text-wrap"
                    >
                      Total
                      <br />
                      {{ (totalAmount + adjustmentsTotal) | toCurrency }}
                      <p></p>
                      Média mensal
                      <br />
                      {{ average | toCurrency }}
                    </h4>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="10">
        <b-card no-body class="card-statistics">
          <b-card-header class="p-2 justify-content-start">
            <b-card-title class="mr-2">Totalizadores</b-card-title>
            <feather-icon
              v-if="eyeOpenTotalizer"
              icon="EyeIcon"
              class="mouse-pointer"
              size="18"
              stroke-width="3"
              @click="toSeeTotalizer()"
            />
            <feather-icon
              v-if="!eyeOpenTotalizer"
              icon="EyeOffIcon"
              class="mouse-pointer"
              size="18"
              stroke-width="3"
              @click="toSeeTotalizer()"
            />
          </b-card-header>
          <div class="w-100 overflow-auto">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col" colspan="2" class="text-center">
                    <span
                      class="commission-totalizer-title"
                      :class="[eyeOpenTotalizer ? '' : 'blur-effect']"
                    >
                      EPs
                      {{
                        periodType !== periodTypes.PAST ? "previstas" : ""
                      }}</span
                    >
                  </th>
                  <th scope="col" colspan="2" class="text-center">
                    <span
                      class="commission-totalizer-title"
                      :class="[eyeOpenTotalizer ? '' : 'blur-effect']"
                    >
                      Assinaturas
                      {{ periodType !== periodTypes.PAST ? "previstas" : "" }}
                    </span>
                  </th>
                  <th scope="col" colspan="2" class="text-center">
                    <span
                      class="commission-totalizer-title"
                      :class="[eyeOpenTotalizer ? '' : 'blur-effect']"
                    >
                      Soluções
                      {{ periodType !== periodTypes.PAST ? "previstas" : "" }}
                    </span>
                  </th>
                  <th
                    scope="col"
                    colspan="2"
                    class="text-center commission-totalizer-title"
                    :class="[eyeOpenTotalizer ? '' : 'blur-effect']"
                  >
                    Lançamentos
                  </th>
                  <th
                    scope="col"
                    colspan="2"
                    class="text-center commission-totalizer-title"
                    :class="[eyeOpenTotalizer ? '' : 'blur-effect']"
                  >
                    Total
                  </th>
                </tr>
                <tr>
                  <th scope="row"></th>
                  <th
                    class="text-center"
                    :class="[eyeOpenTotalizer ? '' : 'blur-effect']"
                  >
                    Valor
                  </th>
                  <th
                    class="text-center"
                    :class="[eyeOpenTotalizer ? '' : 'blur-effect']"
                  >
                    Qtde
                  </th>
                  <th
                    class="text-center"
                    :class="[eyeOpenTotalizer ? '' : 'blur-effect']"
                  >
                    Valor
                  </th>
                  <th
                    class="text-center"
                    :class="[eyeOpenTotalizer ? '' : 'blur-effect']"
                  >
                    Qtde
                  </th>
                  <th
                    class="text-center"
                    :class="[eyeOpenTotalizer ? '' : 'blur-effect']"
                  >
                    Valor
                  </th>
                  <th
                    class="text-center"
                    :class="[eyeOpenTotalizer ? '' : 'blur-effect']"
                  >
                    Qtde
                  </th>
                  <th
                    class="text-center"
                    :class="[eyeOpenTotalizer ? '' : 'blur-effect']"
                  >
                    Valor
                  </th>
                  <th
                    class="text-center"
                    :class="[eyeOpenTotalizer ? '' : 'blur-effect']"
                  >
                    Qtde
                  </th>
                  <th
                    class="text-center"
                    :class="[eyeOpenTotalizer ? '' : 'blur-effect']"
                  >
                    Valor
                  </th>
                  <th
                    class="text-center"
                    :class="[eyeOpenTotalizer ? '' : 'blur-effect']"
                  >
                    Qtde
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">
                    <span
                      class="commission-totalizer-title"
                      :class="[eyeOpenTotalizer ? '' : 'blur-effect']"
                      style="float: right"
                      >{{
                        periodType !== periodTypes.PAST
                          ? "Previstas"
                          : "Realizadas"
                      }}</span
                    >
                  </th>
                  <td class="text-center">
                    <span
                      class="font-weight-bolder mb-0"
                      :class="[eyeOpenTotalizer ? '' : 'blur-effect']"
                      @click="toSeeTotalizer"
                    >
                      {{
                        (periodType !== periodTypes.PAST
                          ? totalsByType.ep_expected_amount
                          : totalsByType.ep_paid_amount) || 0 | toCurrency
                      }}
                    </span>
                  </td>
                  <td class="text-center">
                    <span
                      class="font-weight-bolder mb-0"
                      :class="[eyeOpenTotalizer ? '' : 'blur-effect']"
                      @click="toSeeTotalizer"
                    >
                      {{
                        (periodType !== periodTypes.PAST
                          ? totalsByType.ep_expected_count
                          : totalsByType.ep_paid_count) || 0
                      }}
                    </span>
                  </td>
                  <td class="text-center">
                    <span
                      class="font-weight-bolder mb-0"
                      :class="[eyeOpenTotalizer ? '' : 'blur-effect']"
                      @click="toSeeTotalizer"
                    >
                      {{
                        (periodType !== periodTypes.PAST
                          ? totalsByType.subscription_expected_amount
                          : totalsByType.subscription_paid_amount) ||
                        0 | toCurrency
                      }}
                    </span>
                  </td>
                  <td class="text-center">
                    <span
                      class="font-weight-bolder mb-0"
                      :class="[eyeOpenTotalizer ? '' : 'blur-effect']"
                      @click="toSeeTotalizer"
                    >
                      {{
                        (periodType !== periodTypes.PAST
                          ? totalsByType.subscription_expected_count
                          : totalsByType.subscription_paid_count) || 0
                      }}
                    </span>
                  </td>
                  <td class="text-center">
                    <span
                      class="font-weight-bolder mb-0"
                      :class="[eyeOpenTotalizer ? '' : 'blur-effect']"
                      @click="toSeeTotalizer"
                    >
                      {{
                        (periodType !== periodTypes.PAST
                          ? totalsByType.financial_solutions_expected_amount
                          : totalsByType.financial_solutions_paid_amount) ||
                        0 | toCurrency
                      }}
                    </span>
                  </td>
                  <td class="text-center">
                    <span
                      class="font-weight-bolder mb-0"
                      :class="[eyeOpenTotalizer ? '' : 'blur-effect']"
                      @click="toSeeTotalizer"
                    >
                      {{
                        (periodType !== periodTypes.PAST
                          ? totalsByType.financial_solutions_expected_count
                          : totalsByType.financial_solutions_paid_count) || 0
                      }}
                    </span>
                  </td>
                  <td class="text-center">
                    <span
                      class="font-weight-bolder mb-0"
                      :class="[eyeOpenTotalizer ? '' : 'blur-effect']"
                      @click="toSeeTotalizer"
                    >
                      {{ adjustmentsTotal | toCurrency }}
                    </span>
                  </td>
                  <td class="text-center">
                    <span
                      class="font-weight-bolder mb-0"
                      :class="[eyeOpenTotalizer ? '' : 'blur-effect']"
                      @click="toSeeTotalizer"
                    >
                      {{ adjustmentsQuantity }}
                    </span>
                  </td>
                  <td class="text-center">
                    <span
                      class="font-weight-bolder mb-0"
                      :class="[eyeOpenTotalizer ? '' : 'blur-effect']"
                      @click="toSeeTotalizer"
                    >
                      {{ (totalAmount + adjustmentsTotal) | toCurrency }}
                    </span>
                  </td>
                  <td class="text-center">
                    <span
                      class="font-weight-bolder mb-0"
                      :class="[eyeOpenTotalizer ? '' : 'blur-effect']"
                      @click="toSeeTotalizer"
                    >
                      {{ totalQuantity + adjustmentsQuantity || 0 }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <b-card-body class="px-3">
            <b-row
              class="justify-content-around justify-content-lg-between flex-wrap"
            >
              <b-col cols="12" class="mt-1 text-center">
                <b-media class="w-100" body>
                  <b-media-body class="my-auto w-100">
                    <feather-icon
                      v-if="!visible"
                      icon="ChevronDownIcon"
                      class="text-danger"
                      size="45"
                      v-b-toggle.accordion-1
                      @click="visible = !visible"
                    />
                    <feather-icon
                      v-if="visible"
                      icon="ChevronUpIcon"
                      class="text-danger"
                      size="45"
                      v-b-toggle.accordion-1
                      @click="visible = !visible"
                    />
                    <b-collapse
                      id="accordion-1"
                      accordion="my-accordion"
                      role="tabpanel"
                      class="w-100"
                      :visible="visible"
                    >
                      <div
                        :class="[
                          eyeOpenTotalizer ? '' : 'blur-effect-chart',
                          'w-100 d-flex align-items-center justify-content-center',
                        ]"
                      >
                        <commissions-past-future-chart
                          :spinnerActive="loading"
                          :unBlur="toSeeTotalizer"
                          :periodType="periodType"
                        />
                      </div>
                    </b-collapse>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BPopover,
  BButton,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { mapActions, mapGetters } from "vuex";
import WithdrawalRequestSidebar from "@/modules/withdrawal/components/WithdrawalRequestSidebar";
import * as commissionPeriods from "@/constants/commission_periods";
import * as sharedTypes from "@/modules/shared/store/types";
import * as withdrawalTypes from "@/modules/withdrawal/store/types";
import * as accountTypes from "@/modules/account/store/types";
import CommissionsPastFutureChart from "./CommissionsPastFutureChart.vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    VueApexCharts,
    BPopover,
    BButton,
    WithdrawalRequestSidebar,
    CommissionsPastFutureChart,
  },
  props: {
    result: Object,
    periodType: String,
    loading: Boolean,
  },
  data() {
    return {
      eyeOpenTotalizer: false,
      visible: true,
    };
  },
  computed: {
    ...mapGetters({
      withdrawalSummary: sharedTypes.WITHDRAWAL_SUMMARY,
      presentBillingDate: sharedTypes.PRESENT_BILLING_DATE,
      paymentDeadline: sharedTypes.BILLING_PAYMENT_DEADLINE,
      user: accountTypes.USER,
    }),
    withdrawals: function () {
      return this.withdrawalSummary;
    },
    periodTypes: function () {
      return commissionPeriods;
    },
    itemsQuantity: function () {
      return this.result?.items?.length || 0;
    },
    totalsByType: function () {
      return this.result?.totalsByType || {};
    },
    adjustments: function () {
      return this.result?.adjustments || {};
    },
    adjustmentsTotal: function () {
      return (
        (this.adjustments.credit_amount || 0) -
        (this.adjustments.debit_amount || 0)
      );
    },
    adjustmentsQuantity: function () {
      return this.result?.adjustments?.quantity || 0;
    },
    totalAmount: function () {
      if (this.result?.totalsByType) {
        const {
          ep_expected_amount,
          ep_paid_amount,
          financial_solutions_expected_amount,
          financial_solutions_paid_amount,
          subscription_expected_amount,
          subscription_paid_amount,
        } = this.result.totalsByType;
        if (this.periodType === commissionPeriods.PAST) {
          return (
            (ep_paid_amount || 0) +
            (financial_solutions_paid_amount || 0) +
            (subscription_paid_amount || 0)
          );
        }
        return (
          (ep_expected_amount || 0) +
          (financial_solutions_expected_amount || 0) +
          (subscription_expected_amount || 0)
        );
      }
      return 0;
    },
    totalQuantity: function () {
      if (this.result?.totalsByType) {
        const {
          ep_expected_count,
          ep_paid_count,
          financial_solutions_expected_count,
          financial_solutions_paid_count,
          subscription_expected_count,
          subscription_paid_count,
        } = this.result.totalsByType;
        if (this.periodType === commissionPeriods.PAST) {
          return (
            (ep_paid_count || 0) +
            (financial_solutions_paid_count || 0) +
            (subscription_paid_count || 0)
          );
        }
        return (
          (ep_expected_count || 0) +
          (financial_solutions_expected_count || 0) +
          (subscription_expected_count || 0)
        );
      }
      return 0;
    },
    average: function () {
      return (this.totalAmount + this.adjustmentsTotal) / this.itemsQuantity;
    },
    isEmployee: function () {
      return this.user?.is_employee || false;
    },
  },
  mounted() {
    this.getWithdrawalSummary().catch((error) => {
      this.toast({
        component: ToastificationContent,
        props: {
          title: "Oops!",
          text: "Ocorreu um erro ao recuperar o saldo para saque. Entre em contato com o setor de TI.",
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    });
    this.getPresentBillingDate().catch(() => {
      this.toast({
        component: ToastificationContent,
        props: {
          title: "Oops!",
          text: "Ocorreu um erro ao obter o dia do faturamento. Entre em contato com o setor de TI.",
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    });
    this.getPaymentDeadline().catch(() => {
      this.toast({
        component: ToastificationContent,
        props: {
          title: "Oops!",
          text: "Ocorreu um erro ao obter a quantidade de dias para pagamento. Entre em contato com o setor de TI.",
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    });
  },
  methods: {
    ...mapActions({
      getWithdrawalSummary: sharedTypes.GET_WITHDRAWAL_SUMMARY,
      openWithdrawalRequestSidebar:
        withdrawalTypes.OPEN_WITHDRAWAL_REQUEST_SIDEBAR,
      getPresentBillingDate: sharedTypes.GET_PRESENT_BILLING_DATE,
      getPaymentDeadline: sharedTypes.GET_BILLING_PAYMENT_DEADLINE,
    }),
    toSeeTotalizer() {
      if (event.target.closest(".mouse-pointer")) {
        this.eyeOpenTotalizer = !this.eyeOpenTotalizer;
      } else if (!this.eyeOpenTotalizer) {
        this.eyeOpenTotalizer = true;
      }
    },
    updateBalance() {
      return this.getWithdrawalSummary().catch((error) => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao recuperar o saldo para saque. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    },
  },
};
</script>

<style lang="scss">
.blur-effect-chart {
  filter: blur(15px);
}
</style>

<style lang="scss" scoped>
.commission-totalizer-title {
  font-weight: 500;
  font-size: 1rem;
}

.table th,
.table td {
  padding: 0.72rem;
}

.glass-effect {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.28);
  border-radius: 5px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.blur-effect {
  filter: blur(5px);
}

.mouse-pointer {
  cursor: pointer;
}
</style>
