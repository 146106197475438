<template>
  <div
    v-if="chartData && chartData.series && chartData.series.length"
    class="w-100 p-2"
  >
    <vue-apex-charts
      id="pastChart"
      type="line"
      height="200"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCardSubTitle,
  BCardTitle,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import useAppConfig from "@core/app-config/useAppConfig";
import { toCurrency } from "@/helpers/converters.js";

export default {
  name: "CommissionsPastChart",
  props: {
    chartData: {
      type: Object,
    },
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardSubTitle,
    BCardTitle,
    VueApexCharts,
  },
  setup() {
    const { skin } = useAppConfig();
    return { skin };
  },
  data() {
    return {
      series: [],
      chartOptions: {
        colors: ["#00cfe8", "#3a4dc1", "#f08182", "#48da89"],
        chart: {
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
          foreColor: this.skin === "dark" ? "#fff" : "",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        tooltip: {
          custom(data) {
            return `${'<div class="px-1 py-50"><span>'}${toCurrency(
              data.series[data.seriesIndex][data.dataPointIndex]
            )}</span></div>`;
          },
        },
        xaxis: {
          categories: [], // Ensure categories is set properly
        },
        yaxis: {
          labels: {
            formatter: (value) => toCurrency(value),
          },
        },
      },
    };
  },
  watch: {
    chartData: {
      handler(newVal) {
        this.updateChartData(newVal);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    updateChartData(newData) {
      if (newData?.series?.length) {
        this.series = newData.series;
        // Invert date format from "YYYY-MM" to "MM-YYYY"
        const invertedCategories = newData.categories.map((date) => {
          const [year, month] = date.split("-");
          return `${month}-${year}`;
        });
        this.chartOptions = {
          ...this.chartOptions,
          xaxis: { ...this.chartOptions.xaxis, categories: invertedCategories },
        };
      } else {
        // Set default empty state
        this.series = [
          {
            name: "Loading...",
            data: [],
          },
        ];
        this.chartOptions = {
          ...this.chartOptions,
          xaxis: { ...this.chartOptions.xaxis, categories: [] },
        };
      }
    },
  },
};
</script>

<style scoped>
.chart-container {
  max-width: 100%; /* Ensures the chart fits within the card */
}
.loading-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5em;
  color: #fff;
}
</style>
